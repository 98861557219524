




















































































import { defineComponent, toRefs, computed } from '@vue/composition-api'
import { useNavbar } from '../hook/useNavbar'
import { coreMenu, newsMenu } from '../constants/index'

export default defineComponent({
  name: 'DeskNavbar',
  setup() {
    const { state, handleClick } = useNavbar()
    const darkMode = computed(() => state.mode === 'dark')

    return {
      ...toRefs(state),
      darkMode,
      coreMenu,
      newsMenu,
      handleClick
    }
  }
})
