var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:['navbar', _vm.darkMode ? 'dark' : 'light']},[_c('div',{staticClass:"navbar-contain"},[_c('div',{staticClass:"navbar-menu"},[_c('div',{class:[
          'navbar-single',
          'right',
          _vm.path == '/' ? '' : _vm.darkMode ? 'dark-hover' : 'light-hover',
          _vm.path == '/' ? (_vm.darkMode ? 'dark-show' : 'light-show') : ''
        ],on:{"click":function($event){return _vm.handleClick('/')}}},[_vm._v(" 首页 ")]),_c('div',{class:[
          'navbar-single',
          'right',
          _vm.path == '/introduct' ? '' : _vm.darkMode ? 'dark-hover' : 'light-hover',
          _vm.path == '/introduct' ? 'light-show' : ''
        ],on:{"click":function($event){return _vm.handleClick('/introduct')}}},[_vm._v(" 公司介绍 ")]),_c('div',{staticClass:"right animate-hover"},[_c('span',{class:[
            'more-contents contents-more',
            {
              'light-hover':
                !_vm.darkMode && !['/zhzy', '/zhkt', '/khfw'].includes(_vm.path)
            },
            { 'light-show': ['/zhzy', '/zhkt', '/khfw'].includes(_vm.path) }
          ]},[_vm._v(" 核心产品与服务 ")]),_c('div',{staticClass:"message-tip"},[_c('div',{class:['message-box  message-more']},_vm._l((_vm.coreMenu),function(item){return _c('div',{key:item.key,staticClass:"message-item",on:{"click":function($event){return _vm.handleClick(item.link)}}},[_vm._v(" "+_vm._s(item.value)+" ")])}),0)])]),_c('div',{staticClass:"animate-hover"},[_c('span',{class:[
            'more-contents contents-news',
            { 'light-hover': !_vm.darkMode && !['/news'].includes(_vm.path) },
            { 'light-show': ['/news'].includes(_vm.path) }
          ]},[_vm._v(" 新闻与动态 ")]),_c('div',{staticClass:"message-tip"},[_c('div',{class:['message-box message-news']},_vm._l((_vm.newsMenu),function(item){return _c('div',{key:item.key,staticClass:"message-item",on:{"click":function($event){return _vm.handleClick(item.link)}}},[_vm._v(" "+_vm._s(item.value)+" ")])}),0)])])]),_c('div',{class:['navbar-logo', _vm.darkMode ? 'dark-logo' : 'light-logo']})])])}
var staticRenderFns = []

export { render, staticRenderFns }